import React from 'react';
import styled from 'styled-components';
import { IonAvatar, IonImg, IonItem, IonText } from '@ionic/react';
import { format } from 'date-fns';
import { ChatMessage as ChatMessageDto } from '@echtech/iron-core';

interface MessageProps {
  message: ChatMessageDto;
  supplierId: number;
  sellerImg?: string;
  buyerImg?: string;
  buyerId: number;
  sellerId: number;
}

const ChatMessage: React.FC<MessageProps> = ({
  message,
  supplierId: supplierLoggedId,
  buyerId,
  buyerImg,
  sellerId,
  sellerImg,
}) => {
  const isSender = supplierLoggedId === message?.supplierSenderId;

  const date = format(message.timestamp, 'dd/LL/yyyy hh:mm a');

  const supplierSenderId = supplierLoggedId === buyerId ? buyerId : sellerId;
  const supplierSenderImg = supplierLoggedId === buyerId ? buyerImg : sellerImg;
  const supplierRecipientImg =
    sellerId !== supplierSenderId ? sellerImg : buyerImg;

  return (
    <IonItem lines="none">
      {isSender ? (
        <>
          <MessageContainer slot="end" $isSender>
            <IonText>{message?.message}</IonText>
            <DateLabel>{date}</DateLabel>
          </MessageContainer>
          <IonAvatar slot="end">
            <IonImg src={supplierSenderImg} />
          </IonAvatar>
        </>
      ) : (
        <>
          <IonAvatar slot="start">
            <IonImg src={supplierRecipientImg} />
          </IonAvatar>
          <MessageContainer>
            <IonText className="ion-no-margin">{message?.message}</IonText>
            <DateLabel>{date}</DateLabel>
          </MessageContainer>
        </>
      )}
    </IonItem>
  );
};

const MessageContainer = styled.div<{ $isSender?: boolean }>`
  padding: 0.5em;
  margin: 0em;
  max-width: ${({ $isSender }) => ($isSender ? '85%' : '100%')};
  ${({ $isSender = false }) =>
    $isSender
      ? `border-radius: 0.9375em 0.9375em 0em 0.9375em; background-color: rgba(var(--ion-color-primary-rgb), 0.15);`
      : 'border-radius: 0.9375em 0.9375em 0.9375em 0em; background-color: rgba(var(--ion-color-light-rgb), 0.7);'};
  overflow: auto;
  ion-text {
    white-space: normal;
  }
`;

const DateLabel = styled.span`
  padding-top: 0.5em;
  display: block;
  font-size: 0.7rem;
`;

export default ChatMessage;
