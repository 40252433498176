import { IonImg, IonLabel, IonPage, IonRow, IonSpinner } from '@ionic/react';
import { useI18n } from '@echtech/iron-core';
import styled from 'styled-components';

const FallbackPage = () => {
  const { t } = useI18n();
  return (
    <IonPage>
      <Row>
        <IonImg
          alt="cafe"
          src="../assets/images/cafe.png"
          className="ion-padding-bottom readonly"
        />
        <Spinner name="crescent" color="success" />
        <Label className="ion-padding" color="medium">
          {t('pleaseWait')}
        </Label>
      </Row>
    </IonPage>
  );
};

const Row = styled(IonRow)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Label = styled(IonLabel)`
  font-size: 1.4rem;
`;

const Spinner = styled(IonSpinner)`
  width: 2.5em;
  height: 2.5em;
`;
export default FallbackPage;
