import { AccessRegistry } from '@echtech/iron-core';

const accessRegistry: AccessRegistry = [
  {
    url: '/accessLevelInfo',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/address',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/agreementRequest',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/agreementRequests/seller',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/analyzeAgreementRequest',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/analyzeCredit',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/applied-promotion',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/areaList',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/businessSettings',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/withCheckouts',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/configure-product/new',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/configure-product/update',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/confirmAndPay',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/creditChargesAndHolds',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/creditRequests',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/creditsGranted',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/dayClosingProcess',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/deliveryAddress',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/expirationCharges',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/execute-expense',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/expenseProofEditor',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/expenseProofReview',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/fixedExpenses',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/generateOrder',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/generateOrderWrapper',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/initialInventory',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/inventory/maxMin',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/inventoryViewer',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/inventory-costs',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/inventoryAdjustment',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/invoicingInformation',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/inventoryOptions',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/listMonthlyExpenses',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/modificationOItem',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/modifyCredit',
    accessLevel: 2,
    subscriptionLevel: 2,
  },
  {
    url: '/multipleCarts',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/myAgreementRequestsList',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/myCreditRequests',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/myCredits',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/mySales',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/newAddress',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/newArea',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/newContact',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/newCredit',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/newdDeliveryAddress',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/newFixedExpenses',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/newInvoicingInfo',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/newIssues',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/newOneTimeExpense',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/newPack',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/newPresentation',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/newTask',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/noticeOfPrivacy',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/orderDetail',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/orderItemsByStatus',
    accessLevel: 0,
    subscriptionLevel: 1,
  },
  {
    url: '/orderPdf',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/orders',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/orderServices',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/orderSupplys',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/paymentMethods',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/individualCharges',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/pendingToConfirm',
    accessLevel: 0,
    subscriptionLevel: 1,
  },
  {
    url: '/waitingToPickupOrders',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/onDeliveryOrders',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/onDeliveryOrderDetail',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/pendingToSupply',
    accessLevel: 0,
    subscriptionLevel: 1,
  },
  {
    url: '/plannedChecklist',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/plannedTasks/editor',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/plannedTasks/viewer',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/presentations',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/problemSolution',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/problemsRefunds',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/productConversions',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/products',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/productsConverter',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/promotion/new',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/promotion/update',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/promotionsList',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/purchaseNeeds',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/quotes',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/reportedProblems',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/salesCatalog',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/productsCatalog',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/saleDetail',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/salesPdf',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/salesProblemReport',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/schedule',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/searchTags',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/services',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/shoppingList',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/subscriptionPlans',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/subscriptionPlansInfo',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/supplierAccounts',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/account-movements',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/systemInbox',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/taskViewer',
    accessLevel: -1,
    subscriptionLevel: 0,
    isPageAccessLevelNegativeOneException: true,
  },
  {
    url: '/termsOfUse',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/trackingIssue',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/userManagement',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/wastages',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/wastagesHistory',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/withUpdateContact',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/newSupplierAccount',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/withUpdateSupplierAccount',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/inventory-movements',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/wallet',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/systemInbox',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/supplierService/rates',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/otherEntries',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/receiveInvoice',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/invoicesAndReceiptsMailbox',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/openCloseCashDesk',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/return',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/paymentsReceivedDetails',

    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/deliverymen/list',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/receiveAndDeliverCash',
    accessLevel: 0,
    subscriptionLevel: 0,
  },
  {
    url: '/multipleTags',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/multipleTags',
    accessLevel: 1,
    subscriptionLevel: 1,
  },
  {
    url: '/costHistory',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/salesPriceHistory',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/transferAreas',
    accessLevel: 1,
    subscriptionLevel: 0,
  },
  {
    url: '/iamProducer',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/configureLots',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/myExpenses',
    accessLevel: 0,
    subscriptionLevel: 1,
  },
  {
    url: '/chats',
    accessLevel: 0,
    subscriptionLevel: 1,
  },
  {
    url: '/historyCloseDay',
    accessLevel: 2,
    subscriptionLevel: 1,
  },
  {
    url: '/currentSubscriptionPlan',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/initialTaskGenerator',
    accessLevel: 2,
    subscriptionLevel: 0,
  },
  {
    url: '/detailedInventory',
    accessLevel: 1,
    subscriptionLevel: 2,
  },
  {
    url: '/recipes',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
  {
    url: '/recipe',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
  {
    url: '/procedure',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
  {
    url: '/ingredients',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
  {
    url: '/recipeProcedure',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
  {
    url: '/recipeView',
    accessLevel: 0,
    subscriptionLevel: 2,
  },
];

export default accessRegistry;
