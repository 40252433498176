import React from "react";
import { useReducer } from "react";
import { Types } from "ably";

interface State {
  conversationChannel: Types.RealtimeChannelPromise | null;
  unreadMessageCounts: Record<string, number>;
}

type Action =
  | { type: "setState"; data: Partial<State> }
  | { type: "setUnreadMessageCounts"; data: Record<string, number> };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.data,
      };
    case "setUnreadMessageCounts":
      return {
        ...state,
        unreadMessageCounts: {
          ...state.unreadMessageCounts,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

const useChatReducer = () => {
  const [state, dispatch] = useReducer(reducer, {
    unreadMessageCounts: {},
    conversationChannel: null,
  });

  return {
    state,
    setState: (data: Partial<State>) => dispatch({ data, type: "setState" }),
    setUnreadMessageCounts: (data: Record<string, number>) =>
      dispatch({ data, type: "setUnreadMessageCounts" }),
  };
};

export default useChatReducer;
